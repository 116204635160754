exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-custom-tsx": () => import("./../../../src/pages/custom.tsx" /* webpackChunkName: "component---src-pages-custom-tsx" */),
  "component---src-pages-dashboard-admin-index-tsx": () => import("./../../../src/pages/dashboard/admin/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-index-tsx" */),
  "component---src-pages-dashboard-admin-users-tsx": () => import("./../../../src/pages/dashboard/admin/users.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-users-tsx" */),
  "component---src-pages-dashboard-database-index-tsx": () => import("./../../../src/pages/dashboard/database/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-database-index-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-login-tsx": () => import("./../../../src/pages/dashboard/login.tsx" /* webpackChunkName: "component---src-pages-dashboard-login-tsx" */),
  "component---src-pages-dashboard-projects-index-tsx": () => import("./../../../src/pages/dashboard/projects/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-projects-index-tsx" */),
  "component---src-pages-dashboard-settings-index-tsx": () => import("./../../../src/pages/dashboard/settings/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-settings-index-tsx" */),
  "component---src-pages-dashboard-subscribe-index-tsx": () => import("./../../../src/pages/dashboard/subscribe/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-subscribe-index-tsx" */),
  "component---src-pages-dashboard-subscribe-price-tsx": () => import("./../../../src/pages/dashboard/subscribe/Price.tsx" /* webpackChunkName: "component---src-pages-dashboard-subscribe-price-tsx" */),
  "component---src-pages-dashboard-subscriptions-index-tsx": () => import("./../../../src/pages/dashboard/subscriptions/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-subscriptions-index-tsx" */),
  "component---src-pages-dashboard-subscriptions-subscription-tsx": () => import("./../../../src/pages/dashboard/subscriptions/subscription.tsx" /* webpackChunkName: "component---src-pages-dashboard-subscriptions-subscription-tsx" */),
  "component---src-pages-dashboard-user-index-tsx": () => import("./../../../src/pages/dashboard/user/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-user-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internet-issues-tsx": () => import("./../../../src/pages/internet-issues.tsx" /* webpackChunkName: "component---src-pages-internet-issues-tsx" */),
  "component---src-pages-jenkins-tsx": () => import("./../../../src/pages/jenkins.tsx" /* webpackChunkName: "component---src-pages-jenkins-tsx" */),
  "component---src-pages-joomla-tsx": () => import("./../../../src/pages/joomla.tsx" /* webpackChunkName: "component---src-pages-joomla-tsx" */),
  "component---src-pages-legal-delivery-policy-tsx": () => import("./../../../src/pages/legal/delivery-policy.tsx" /* webpackChunkName: "component---src-pages-legal-delivery-policy-tsx" */),
  "component---src-pages-legal-payment-method-tsx": () => import("./../../../src/pages/legal/payment-method.tsx" /* webpackChunkName: "component---src-pages-legal-payment-method-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-legal-refund-tsx": () => import("./../../../src/pages/legal/refund.tsx" /* webpackChunkName: "component---src-pages-legal-refund-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-mongodb-tsx": () => import("./../../../src/pages/mongodb.tsx" /* webpackChunkName: "component---src-pages-mongodb-tsx" */),
  "component---src-pages-moodle-tsx": () => import("./../../../src/pages/moodle.tsx" /* webpackChunkName: "component---src-pages-moodle-tsx" */),
  "component---src-pages-mysql-tsx": () => import("./../../../src/pages/mysql.tsx" /* webpackChunkName: "component---src-pages-mysql-tsx" */),
  "component---src-pages-nextcloud-tsx": () => import("./../../../src/pages/nextcloud.tsx" /* webpackChunkName: "component---src-pages-nextcloud-tsx" */),
  "component---src-pages-odoo-tsx": () => import("./../../../src/pages/odoo.tsx" /* webpackChunkName: "component---src-pages-odoo-tsx" */),
  "component---src-pages-ollama-tsx": () => import("./../../../src/pages/ollama.tsx" /* webpackChunkName: "component---src-pages-ollama-tsx" */),
  "component---src-pages-openvpn-tsx": () => import("./../../../src/pages/openvpn.tsx" /* webpackChunkName: "component---src-pages-openvpn-tsx" */),
  "component---src-pages-openwebui-tsx": () => import("./../../../src/pages/openwebui.tsx" /* webpackChunkName: "component---src-pages-openwebui-tsx" */),
  "component---src-pages-postgresql-tsx": () => import("./../../../src/pages/postgresql.tsx" /* webpackChunkName: "component---src-pages-postgresql-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-wordpress-tsx": () => import("./../../../src/pages/wordpress.tsx" /* webpackChunkName: "component---src-pages-wordpress-tsx" */)
}

