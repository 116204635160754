import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../src/slices/user';
import messageReducer from '../src/slices/message';
import serviceReducer from '../src/slices/services';
import workspaceReducer from '../src/slices/workspaces';
import productReducer from '../src/slices/product';
import planReducer from '../src/slices/plan';
import subscriptionReducer from '../src/slices/subscription';
import bankInfoReducer from '../src/slices/bankInfo';
import orderReducer from '../src/slices/order';

const reducer = {
  user: userReducer,
  message: messageReducer,
  service: serviceReducer,
  workspace: workspaceReducer,
  product: productReducer,
  plan: planReducer,
  subscription: subscriptionReducer,
  bankinfo: bankInfoReducer,
  order: orderReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
