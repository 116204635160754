module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-W3D95Q2LRM"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nebuacloud SaaS OpenSource Platform","short_name":"Nebuacloud","description":"Cloud Nebuacloud SaaS OpenSource Platform","start_url":"/dashboard","background_color":"#95C11F","theme_color":"#95C11F","display":"standalone","icon":"src/assets/img/favicon.png","crossOrigin":"use-credentials","icons":[{"src":"/favicons/pwa-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/pwa-icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"0b85ae8feeaf47d4b481c6ca58bb417d"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
