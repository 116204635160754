import axiosHelper from '../helper/axiosHelper';
import * as env from '../../env';
import { IContextRepo, IContributor } from '../interfaces/interfaces';

const paramHeaders = {
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
};

const serverUrl = env.httpProtocol.concat(
  env.serverHost,
  ':',
  env.serverPort,
  '/namespace',
);

export const getNamespaceInfoAPI = async (owner: number, namespace: string) => {
  const url = serverUrl.concat(
    '/namespace/',
    owner.toString(),
    '/',
    namespace.toString(),
  );
  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const getNamespaceContributors = async () => {
  const url = serverUrl + '/get-contributors';

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const deleteServiceAPI = async (jsonBody: any) => {
  const url = serverUrl + '/delete-service';
  return axiosHelper.axiosPost(url, jsonBody, paramHeaders);
};

export const addNamespaceContributorsAPI = async (
  contributor: IContributor,
) => {
  const url = serverUrl + '/add-contributors';
  return axiosHelper.axiosPost(url, contributor, paramHeaders);
};

export const deleteNamespaceContributorAPI = async (email: string) => {
  const url = serverUrl + '/delete-contributor';
  return axiosHelper.axiosPost(url, { email }, paramHeaders);
};

export const getWorkspacesAPI = async () => {
  const url = serverUrl + '/get-workspaces';
  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const changeSessionNamespaceAPI = async (namespace: string) => {
  const url = serverUrl + '/change-namespace';
  return axiosHelper.axiosPost(url, { namespace }, paramHeaders);
};

export const addOrUpdateNamespaceAPI = async (name: string) => {
  const url = serverUrl + '/add-namespace';
  return axiosHelper.axiosPost(url, { name }, paramHeaders);
};

export const deleteNamespaceAPI = async (code: string) => {
  const url = serverUrl + '/delete-namespace';
  return axiosHelper.axiosPost(url, { code }, paramHeaders);
};

export const getNamespaceServicesAPI = async () => {
  const url = serverUrl + '/services';

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const addContextRepoAPI = async (contextRepo: IContextRepo) => {
  const url = serverUrl + '/add-context-repo';

  const result = await axiosHelper.axiosPost(url, contextRepo, paramHeaders);
  return result;
};

export const getContextReposAPI = async () => {
  const url = serverUrl + '/context-repos';

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const deleteContextReposAPI = async (contextRepoName: string) => {
  const url = serverUrl + '/delete-context-repo';

  const result = await axiosHelper.axiosPost(
    url,
    { contextRepoName: contextRepoName },
    paramHeaders,
  );
  return result;
};
