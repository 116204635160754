import React from 'react';
import { Provider } from 'react-redux';
import store from './state/store';

//type Provider = /*unresolved*/ any

export default ({ element }) => {
  // return <Provider >{element};
  return <Provider store={store}>{element}</Provider>;
};
